var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data && _vm.data.categories && _vm.filteredCategories.length > 0)?_c('div',{staticClass:"home-collection"},[_c('div',{staticClass:"home-collection__title"},[_c('span',[_vm._v(_vm._s(_vm.data.title))])]),_vm._v(" "),(_vm.isDesktop)?_c('div',{staticClass:"home-collection__list"},_vm._l((_vm.filteredCategories),function(homeCategory,index){return _c('div',{key:("home-category-" + index),staticClass:"home-collection__list__category"},[_c('div',{staticClass:"category-image"},[(
            homeCategory.image &&
            ((_vm.isDesktop && homeCategory.image.desktop) ||
              (!_vm.isDesktop && homeCategory.image.mobile))
          )?_c('nuxt-img',{staticClass:"category-nuxt-img-desktop",attrs:{"src":_vm.isDesktop ? homeCategory.image.desktop : homeCategory.image.mobile,"preload":"","loading":"lazy"}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"category-info"},[(homeCategory.link)?_c('div',{staticClass:"category-title"},[_c('CustomCta',{attrs:{"type":"label","link":_vm.localePath(homeCategory.link['link-url']),"target":homeCategory.link.target || '_blank'}},[_c('span',{staticClass:"category-label"},[_vm._v("\n              "+_vm._s(homeCategory.link['link-label'])+"\n            ")])])],1):_vm._e(),_vm._v(" "),(homeCategory.description)?_c('div',{staticClass:"category-description"},[_c('span',[_vm._v(_vm._s(homeCategory.description))])]):_vm._e()])])}),0):_c('div',{staticClass:"home-collection__list__category__mobile"},[_c('VueSlickCarousel',_vm._b({staticClass:"slick-home-collection"},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.filteredCategories),function(homeCategory,index){return _c('div',{key:("home-category-" + index),staticClass:"slick-home-collection__item"},[_c('div',{staticClass:"category-image"},[(
              homeCategory.image &&
              ((_vm.isDesktop && homeCategory.image.desktop) ||
                (!_vm.isDesktop && homeCategory.image.mobile))
            )?_c('nuxt-img',{staticClass:"category-nuxt-img-mobile",attrs:{"src":_vm.isDesktop
                ? homeCategory.image.desktop
                : homeCategory.image.mobile,"preload":"","loading":"lazy","width":"220","height":"220"}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"category-info"},[(homeCategory.link)?_c('div',{staticClass:"category-title"},[_c('CustomCta',{attrs:{"type":"label","link":_vm.localePath(homeCategory.link['link-url'])}},[_c('span',{staticClass:"category-label"},[_vm._v("\n                "+_vm._s(homeCategory.link['link-label'])+"\n              ")])])],1):_vm._e(),_vm._v(" "),(homeCategory.description)?_c('div',{staticClass:"category-description"},[_c('span',[_vm._v(_vm._s(homeCategory.description))])]):_vm._e()])])}),0)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }