<template>
  <div
    v-if="data && data.categories && filteredCategories.length > 0"
    class="home-collection"
  >
    <div class="home-collection__title">
      <span>{{ data.title }}</span>
    </div>
    <div v-if="isDesktop" class="home-collection__list">
      <div
        class="home-collection__list__category"
        v-for="(homeCategory, index) in filteredCategories"
        :key="`home-category-${index}`"
      >
        <div class="category-image">
          <nuxt-img
            v-if="
              homeCategory.image &&
              ((isDesktop && homeCategory.image.desktop) ||
                (!isDesktop && homeCategory.image.mobile))
            "
            class="category-nuxt-img-desktop"
            :src="
              isDesktop ? homeCategory.image.desktop : homeCategory.image.mobile
            "
            preload
            loading="lazy"
          />
        </div>
        <div class="category-info">
          <div class="category-title" v-if="homeCategory.link">
            <CustomCta
              type="label"
              class=""
              :link="localePath(homeCategory.link['link-url'])"
              :target="homeCategory.link.target || '_blank'"
            >
              <span class="category-label">
                {{ homeCategory.link['link-label'] }}
              </span>
            </CustomCta>
          </div>
          <div class="category-description" v-if="homeCategory.description">
            <span>{{ homeCategory.description }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="home-collection__list__category__mobile">
      <VueSlickCarousel v-bind="settings" class="slick-home-collection">
        <div
          v-for="(homeCategory, index) in filteredCategories"
          :key="`home-category-${index}`"
          class="slick-home-collection__item"
        >
          <div class="category-image">
            <nuxt-img
              v-if="
                homeCategory.image &&
                ((isDesktop && homeCategory.image.desktop) ||
                  (!isDesktop && homeCategory.image.mobile))
              "
              class="category-nuxt-img-mobile"
              :src="
                isDesktop
                  ? homeCategory.image.desktop
                  : homeCategory.image.mobile
              "
              preload
              loading="lazy"
              width="220"
              height="220"
            />
          </div>
          <div class="category-info">
            <div class="category-title" v-if="homeCategory.link">
              <CustomCta
                type="label"
                class=""
                :link="localePath(homeCategory.link['link-url'])"
              >
                <span class="category-label">
                  {{ homeCategory.link['link-label'] }}
                </span>
              </CustomCta>
            </div>
            <div class="category-description" v-if="homeCategory.description">
              <span>{{ homeCategory.description }}</span>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useWindow } from '~/composables';
import { CustomCta } from '~/components/General';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

export default defineComponent({
  name: 'CollectionHome',
  components: {
    VueSlickCarousel,
    CustomCta,
  },
  props: {
    data: {
      type: Object,
      default: () => null,
    },
  },
  setup(props) {
    const { isDesktop } = useWindow();

    const filteredCategories = computed(() =>
      props.data?.categories
        ? props.data.categories.filter((c) => c && c.active)
        : []
    );

    return {
      isDesktop,
      filteredCategories,
      settings: {
        arrows: false,
        dots: false,
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 1,
        speed: 400,
        variableWidth: true,
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.home-collection {
  @include to-tablet-max {
    margin-bottom: var(--spacer-lg);
  }
  @include from-desktop-min {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-template-rows: 1fr;
    grid-column-gap: var(--spacer-base);
    grid-row-gap: var(--spacer-base);
    border-top: 1px solid var(--c-black);
  }
  &__title {
    padding-left: var(--spacer-base);
    padding-top: 2.5rem;
    @include to-tablet-max {
      padding-bottom: var(--spacer-base);
    }
    span {
      @include mobile-h4;
    }
    @include from-desktop-min {
      border-right: 1px solid var(--c-black);
      padding-top: var(--spacer-base);
      span {
        @include desktop-h4;
        display: block;
        max-width: 23.75rem;
      }
    }
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: var(--spacer-base);
    grid-row-gap: 0px;
    &__category {
      display: grid;
      grid-template-columns: 1fr 1.5fr;
      grid-template-rows: 1fr;
      grid-column-gap: var(--spacer-base);
      grid-row-gap: 0px;
      padding-top: var(--spacer-base);
      padding-bottom: var(--spacer-base);
      border-bottom: 1px solid rgb(128, 128, 128, 0.6);
      &:last-child {
        border-bottom: none;
      }
      .category-nuxt-img-desktop {
        width: 100%;
        height: 100%;
      }
      &__mobile {
        padding-left: var(--spacer-base);
      }
    }
  }
  .slick-home-collection {
    &__item {
      max-width: 13.75rem;
      margin-right: var(--spacer-sm);
    }
  }
  .category-info {
    .category-title {
      margin-bottom: var(--spacer-sm);

      @include to-tablet-max {
        margin-top: var(--spacer-sm);
      }
      .category-label {
        @include desktop-h6;

        @include to-tablet-max {
          @include mobile-h6;
        }
      }
    }
    .category-description {
      span {
        @include paragraph-s;
      }
    }
  }
}
</style>
